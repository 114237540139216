import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/page-header"
import Container from "../components/container"
import PostPreview from "../components/post-preview"
import styles from "./Haberler.module.scss"

export default function Haberler({ data }) {
  return (
    <Layout>
      <SEO title="Haberler" image={data.pageHeaderImage.sharp.fluid} />
      <PageHeader title="Haberler" image={data.pageHeaderImage.sharp.fluid} />
      <Container>
        <div className={styles.posts}>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <PostPreview
              key={node.frontmatter.slug}
              slug={node.frontmatter.slug}
              title={node.frontmatter.title}
              image={node.frontmatter.image.sharp.fluid}
            />
          ))}
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/blog/**/*.md" } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            image {
              sharp: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }

    pageHeaderImage: file(
      relativePath: { eq: "ozel-civata-ozel-parcalar-3.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
